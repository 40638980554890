<script setup lang="ts">
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useProjectStore } from '@/stores/projectStore'
import { useProjectData } from '@/composables/projectData'
import { ref, watch, computed } from 'vue'
import ProjectLocationMap from '@/components/ProjectLocationMap.client.vue'
import AddressInput from '@/components/AddressInput.vue'

const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const { updateProjectDetails, getProjectLocation } = useProjectData()

const userStore = useUserStore()
const { selectedTeam } = storeToRefs(userStore)
const { getImageForProject } = useProjectData()

const formData = ref({
  title: selectedProject.value?.title || '',
  project_location: selectedProject.value?.project_location ? {
    type: 'Point',
    coordinates: selectedProject.value.project_location.coordinates || [0, 0]
  } : null,
  formatted_address: selectedProject.value?.formatted_address || '',
  project_timeline: selectedProject.value?.project_timeline || {
    phases: [],
    start_date: null,
    end_date: null,
    milestones: []
  },
  project_scale: selectedProject.value?.project_scale || null,
  design_objectives: selectedProject.value?.design_objectives || [],
  budget_level: selectedProject.value?.budget_level || null,
  additional_preferences: selectedProject.value?.additional_preferences || {}
})

// Watch for changes in selectedProject
watch(() => selectedProject.value, (newProject) => {
  if (newProject) {
    formData.value = {
      title: newProject.title || '',
      project_location: newProject.project_location ? {
        type: 'Point',
        coordinates: newProject.project_location.coordinates || [0, 0]
      } : null,
      formatted_address: newProject.formatted_address || '',
      project_timeline: newProject.project_timeline || {
        phases: [],
        start_date: null,
        end_date: null,
        milestones: []
      },
      project_scale: newProject.project_scale || null,
      design_objectives: newProject.design_objectives || [],
      budget_level: newProject.budget_level || null,
      additional_preferences: newProject.additional_preferences || {}
    }
  }
}, { immediate: true })

// Add these imports at the top if not already auto-imported
import { watchDebounced } from '@vueuse/core'

// Add watchers for each form field
watchDebounced(
  () => ({...formData.value}),
  async (newData, oldData) => {
    if (!selectedProject.value || JSON.stringify(newData) === JSON.stringify(oldData)) return
    
    try {
      // Remove formatted_address from update if it's empty
      const updates = {...newData}
      if (!updates.formatted_address) {
        delete updates.formatted_address
      }
      
      const updatedProject = await updateProjectDetails(selectedProject.value.id, updates)
    } catch (error) {
      console.error('Error updating project:', error)
    }
  },
  { debounce: 500, deep: true }
)

// Update formattedAddress computed to handle empty values better
const formattedAddress = computed({
  get: () => {
    // Return formatted_address if available, otherwise empty string
    return selectedProject.value?.formatted_address || formData.value.formatted_address || ''
  },
  set: (newValue: string) => {
    formData.value.formatted_address = newValue
  }
})

// Update mapCoordinates computed to use the new structure
const mapCoordinates = computed({
  get: () => ({
    coordinates: formData.value.project_location?.coordinates?.length === 2
      ? [
          formData.value.project_location.coordinates[1], 
          formData.value.project_location.coordinates[0]
        ] as [number, number]
      : [-37.840935, 144.946457] as [number, number],
    formatted_address: formData.value.formatted_address
  }),
  set: (newValue: { coordinates: [number, number], formatted_address?: string }) => {
    const [lat, lng] = newValue.coordinates
    formData.value.project_location = {
      type: 'Point',
      coordinates: [lng, lat] // PostGIS expects [longitude, latitude]
    }
    // Keep the existing formatted_address if not provided in the update
    if (newValue.formatted_address) {
      formData.value.formatted_address = newValue.formatted_address
    }
  }
})

// Add a watcher to sync with the view when project changes
watch(() => selectedProject.value?.id, async (newId) => {
  if (newId) {
    const location = await getProjectLocation(newId)
    if (location?.latitude && location?.longitude) {
      formData.value.project_location = {
        type: 'Point',
        coordinates: [location.longitude, location.latitude]
      }
    }
  }
}, { immediate: true })

// Update handleMapCoordinateChange to handle the new structure
const handleMapCoordinateChange = (value: { coordinates: [number, number], formatted_address?: string }) => {
  console.log('map: handleMapCoordinateChange', value)
  mapCoordinates.value = value
  // Trigger an immediate update to the database
  if (selectedProject.value) {
    const updates: any = {
      project_location: formData.value.project_location
    }
    if (value.formatted_address) {
      updates.formatted_address = value.formatted_address
    }
    updateProjectDetails(selectedProject.value.id, updates)
  }
}

// Update handleAddressSelect to not exit map mode for suggested addresses
const handleAddressSelect = async (address: { description: string, place_id: string }) => {
  try {
    // If this is a suggested address from marker drag, just update the address
    if (address.place_id === 'suggested') {
      const updates = {
        formatted_address: address.description,
        project_location: formData.value.project_location // Use existing coordinates
      }
      
      // Update local state only
      formData.value.formatted_address = address.description
      
      // Reset address prompt but keep map focused
      showAddressUpdatePrompt.value = false
      return
    }

    // Original geocoding logic for searched addresses...
    const { data: locationData } = await useFetch<{
      status: string;
      location?: {
        lat: number;
        lng: number;
      };
      formatted_address?: string;
      error?: string;
    }>('/api/geocode', {
      method: 'POST',
      body: { 
        address: address.description,
        useGoogle: true
      }
    })

    if (locationData.value?.status === 'OK' && locationData.value?.location) {
      const { lat, lng } = locationData.value.location
      const updates: any = {
        project_location: {
          type: 'Point',
          coordinates: [lng, lat] // Array format for PostGIS
        }
      }
      
      // Always use the geocoded formatted_address if available, otherwise use the selected address
      const newAddress = locationData.value.formatted_address || address.description
      updates.formatted_address = newAddress
      
      // Update local state
      formData.value = {
        ...formData.value,
        project_location: updates.project_location,
        formatted_address: newAddress
      }

      // Reset UI state but stay in map mode for searched addresses too
      showAddressUpdatePrompt.value = false
    }
  } catch (error) {
    console.error('Error handling address selection:', error)
  }
}

useSquircleObserver()

// Add state for search focus
const isSearchFocused = ref(false)

// Add handlers for search focus
const handleSearchFocus = () => {
  isSearchFocused.value = true
}

// Add new handlers for explicit location actions
const handleSaveLocation = () => {
  // Commit map changes
  mapRef.value?.commitChanges()
  isSearchFocused.value = false
  showAddressUpdatePrompt.value = false
  suggestedAddress.value = ''
}

const handleCancelLocation = () => {
  // Revert map changes
  mapRef.value?.revertChanges()
  isSearchFocused.value = false
  showAddressUpdatePrompt.value = false
  suggestedAddress.value = ''
}

const isOpen = ref(false)
const triggerRef = ref<HTMLElement | null>(null)

const handleOpenChange = (open: boolean) => {
  isOpen.value = open
}

// Add click handler for the trigger
const handleTriggerClick = () => {
  isOpen.value = !isOpen.value
}

// Add state for suggested address
const suggestedAddress = ref('')
const showAddressUpdatePrompt = ref(false)

// Add handler for map address update
const handleMapAddressUpdate = (address: string) => {
  suggestedAddress.value = address
  showAddressUpdatePrompt.value = true
  isSearchFocused.value = true
  
  // Force focus and open dropdown after a short delay to ensure components are mounted
  setTimeout(() => {
    const addressInput = document.querySelector('input[placeholder="Enter site location"]')
    if (addressInput instanceof HTMLElement) {
      addressInput.focus()
      // Manually trigger the focus event
      addressInput.dispatchEvent(new Event('focus'))
    }
  }, 100)
}

// Add ref for map component
const mapRef = ref(null)
</script>

<template>
  <NavigationMenu 
    v-if="selectedProject" 
    class="relative"
    :disableHoverTrigger="false"
    v-model:open="isOpen"
    @update:open="handleOpenChange"
    rounded="2xl"
  >
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger 
          ref="triggerRef"
          class="px-2 py-1 h-9 flex items-center gap-2 bg-background/50 hover:bg-background data-[state=open]:bg-background/80 squircle-10"
          @click="handleTriggerClick"
        >
          <ParallaxImage 
            :src="getImageForProject(selectedProject?.title || '')"
            width="24px"
            height="24px"
            :blur="false"
            :parallax="false"
            :title="formData.title || ''"
          />
          <span>{{ formData.title }}</span>
        </NavigationMenuTrigger>
        <NavigationMenuContent 
          class="z-50"
          :forceMount="false"
          rounded="2xl"
        >
          <div class="relative w-[400px] h-[600px] overflow-hidden">
            <!-- Full-bleed map -->
            <div 
              v-motion-fade-visible :delay="500"
              class="absolute inset-0"
              :class="[isSearchFocused ? 'z-10' : '-z-10']"
            >
              <ProjectLocationMap
                ref="mapRef"
                :modelValue="mapCoordinates"
                @update:modelValue="handleMapCoordinateChange"
                @showAddressUpdate="handleMapAddressUpdate"
                :popupContent="formattedAddress"
                :fullscreen="true"
              />
            </div>
            
            <!-- Overlay content -->
            <div 
              class="relative h-full transition-all duration-300"
              :class="[isSearchFocused ? 'pointer-events-none' : 'z-20']"
            >
              <!-- Background overlay -->
              <div 
                class="absolute inset-0 transition-all duration-300"
                :class="[
                  isSearchFocused 
                    ? 'bg-transparent pointer-events-none' 
                    : 'bg-background/30 backdrop-blur-sm'
                ]"
              />
              
              <!-- Content -->
              <div class="relative h-full overflow-y-auto">
                <div class="p-4 space-y-4">
                  <div 
                    class="space-y-2 transition-all duration-300"
                    :class="[
                      isSearchFocused 
                        ? 'opacity-0' 
                        : 'opacity-100'
                    ]"
                  >
                    <Label for="title">Project Name</Label>
                    <Input 
                      id="title"
                      v-model="formData.title"
                      placeholder="Enter project name"
                    />
                  </div>

                  <div class="space-y-2">
                    <!-- Combined search and display input -->
                    <div 
                      class="relative z-30"
                      :class="{ 'pointer-events-auto': isSearchFocused }"
                    >
                      <AddressInput
                        v-model="formattedAddress"
                        placeholder="Enter site location"
                        @select="handleAddressSelect"
                        @focus="handleSearchFocus"
                        class="mb-2"
                        :initialValue="selectedProject?.formatted_address"
                        :showUpdatePrompt="showAddressUpdatePrompt"
                        :suggestedAddress="suggestedAddress"
                        @update:showUpdatePrompt="showAddressUpdatePrompt = $event"
                      />
                    </div>
                  </div>

                  <div 
                    class="space-y-2 transition-all duration-300"
                    :class="[
                      isSearchFocused 
                        ? 'opacity-0' 
                        : 'opacity-100'
                    ]"
                  >
                  </div>
                </div>
              </div>
            </div>

            <!-- Location action buttons - highest z-index -->
            <div 
              v-if="isSearchFocused"
              class="absolute inset-0 flex items-end justify-end p-4 pointer-events-none z-30"
            >
              <div class="flex gap-2 pointer-events-auto rounded-md p-6">
                <Button 
                  variant="text" 
                  @click="handleCancelLocation"
                  class="text-muted-foreground hover:text-foreground"
                >
                  Cancel
                </Button>
                <Button 
                  @click="handleSaveLocation"
                >
                  <Icon name="mynaui:location-check-solid" class="w-6 h-6 mr-2" /> Save
                </Button>
              </div>
            </div>
          </div>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
</template>

<style scoped>
.navigation-menu {
  position: relative;
}

:deep(.navigation-menu-content) {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 50;
}

:deep(.navigation-menu-content[data-state='open']) {
  animation: scaleIn 0.2s ease;
}

:deep(.navigation-menu-content[data-state='closed']) {
  animation: scaleOut 0.2s ease;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
</style> 