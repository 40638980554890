<script setup lang="ts">
import { ref, watch, computed, nextTick } from 'vue'
import { useDebounceFn } from '@vueuse/core';
import { useColorMode } from '@vueuse/core'
import { useFetch } from '#imports'
import {
  MglMap,
  MglNavigationControl,
  MglMarker,
  MglPopup,
  useMap
} from '@indoorequal/vue-maplibre-gl'
import type { LngLatLike, Map } from 'maplibre-gl'

const props = defineProps<{
  modelValue: {
    coordinates: [number, number] // [latitude, longitude]
    formatted_address?: string
  }
  fullscreen?: boolean
}>()

const mapLoaded = ref(false)
const map = useMap()

// Add a function to handle initial positioning
const handleInitialPosition = () => {
  if (props.modelValue?.coordinates?.length === 2) {
    const [lat, lng] = props.modelValue.coordinates
    flyToLocation(lng, lat)
  }
}

// Update the map load handler
const onMapLoad = () => {
  mapLoaded.value = true
  handleInitialPosition()
}

const flyToLocation = (lng: number, lat: number) => {
  if (mapLoaded.value && map.map) {
    map.map.flyTo({
      center: [lng, lat],
      zoom: 15,
      duration: 2000,
      essential: true,
      curve: 1.42,
      speed: 1.2,
    })
  }
}

// Update the watch to use deep watching for modelValue
watch(() => props.modelValue, (newValue) => {
  if (newValue?.coordinates?.length === 2) {
    const [lat, lng] = newValue.coordinates
    flyToLocation(lng, lat)
  }
}, { immediate: true, deep: true })

// Watch for map load separately
watch(() => map.isLoaded, (isLoaded) => {
  if (isLoaded) {
    mapLoaded.value = true
    handleInitialPosition()
  }
})

const emit = defineEmits<{
  'update:modelValue': [{
    coordinates: [number, number]
    formatted_address?: string
  }]
  'showAddressUpdate': [string]
}>()

// Update center to use existing coordinates if available
const center = computed<LngLatLike>(() => {
  if (props.modelValue?.coordinates?.length === 2) {
    const [lat, lng] = props.modelValue.coordinates
    return [lng, lat] // MapLibre expects [longitude, latitude]
  }
  return [144.9631, -37.8136] // Default to Melbourne
})

const zoom = ref(12)

// Update markerPosition to match center logic
const markerPosition = computed<LngLatLike>(() => {
  if (props.modelValue?.coordinates?.length === 2) {
    const [lat, lng] = props.modelValue.coordinates
    return [lng, lat] // MapLibre expects [longitude, latitude]
  }
  return [144.946457, -37.840935] // Default to Melbourne
})



const colorMode = useColorMode()
const style = computed(() => 
  colorMode.value === 'dark' 
    ? 'https://api.maptiler.com/maps/outdoor-v2-dark/style.json?key=tVYcCqbqU5I3tpqgTmCu' 
    : 'https://api.maptiler.com/maps/landscape/style.json?key=tVYcCqbqU5I3tpqgTmCu'
)

// Debounced emit function
const debouncedEmit = useDebounceFn((lat: number, lng: number) => {
  if (!dragging.value) {
    emit('update:modelValue', {
      coordinates: [lat, lng],
      formatted_address: props.modelValue?.formatted_address
    })
  }
}, 100) // 300ms debounce delay

// Update click handler to use debounced emit
const handleMapClick = ({ event }: { event: { lngLat: { lng: number, lat: number } } }) => {
  const { lng, lat } = event.lngLat
  debouncedEmit(lat, lng)
}

const dragging = ref(false)

// Store a reference to the marker
const markerRef = ref<any>(null)

// Create a type for marker coordinates
type MarkerCoordinates = {
  lat: number;
  lng: number;
} | [number, number]

// Helper function to extract lat/lng from coordinates
const getLatLng = (coordinates: MarkerCoordinates): { lat: number; lng: number } => {
  if (Array.isArray(coordinates)) {
    return { lng: coordinates[0], lat: coordinates[1] }
  }
  return coordinates
}

// Create a debounced version of the reverse geocoding function
const debouncedReverseGeocode = useDebounceFn(async (lat: number, lng: number) => {
  try {
    // Get the address for these coordinates
    const { data: locationData } = await useFetch('/api/geocode', {
      method: 'POST',
      body: { 
        coordinates: [lat, lng],
        useGoogle: true
      }
    })

    if (locationData.value?.status === 'OK' && locationData.value?.formatted_address) {
      // Emit the address update prompt
      emit('showAddressUpdate', locationData.value.formatted_address)
    }
  } catch (error) {
    console.error('Error reverse geocoding:', error)
  }
}, 1000) // 1 second debounce

// Update marker coordinates handler to use debounced geocoding
const handleMarkerCoordinatesChange = (coordinates: LngLatLike) => {
  const coords = getLatLng(coordinates as MarkerCoordinates)
  
  // Update coordinates immediately
  debouncedEmit(coords.lat, coords.lng)
  
  // Only trigger reverse geocoding when dragging ends
  if (!dragging.value) {
    debouncedReverseGeocode(coords.lat, coords.lng)
  }
}

// Update dragend handler to trigger reverse geocoding
const handleDragEnd = () => {
  dragging.value = false
  
  // Get current marker position and trigger reverse geocoding
  if (markerRef.value) {
    const coordinates = markerRef.value.coordinates as MarkerCoordinates
    const coords = getLatLng(coordinates)
    debouncedReverseGeocode(coords.lat, coords.lng)
  }
}

// Add state to track original values
const originalCoordinates = ref<[number, number] | null>(null)
const originalAddress = ref<string | null>(null)

// Update the watch to store original values when they first change
watch(() => props.modelValue, (newValue) => {
  if (newValue?.coordinates?.length === 2 && !originalCoordinates.value) {
    originalCoordinates.value = [...newValue.coordinates]
    originalAddress.value = newValue.formatted_address || null
  }
}, { immediate: true, deep: true })

// Add method to revert changes
const revertChanges = () => {
  if (originalCoordinates.value) {
    emit('update:modelValue', {
      coordinates: originalCoordinates.value,
      formatted_address: originalAddress.value || undefined
    })
    
    // Reset the map position
    const [lat, lng] = originalCoordinates.value
    flyToLocation(lng, lat)
  }
}

// Add method to commit changes
const commitChanges = () => {
  // Update original values to current values
  if (props.modelValue?.coordinates) {
    originalCoordinates.value = [...props.modelValue.coordinates]
    originalAddress.value = props.modelValue.formatted_address || null
  }
}

// Expose methods to parent
defineExpose({
  revertChanges,
  commitChanges
})

</script>

<template>
  <div :class="[
    'rounded-md overflow-hidden', 
    fullscreen ? 'absolute inset-0 -z-10' : 'w-full h-[200px]'
  ]">
    <ClientOnly>
      <MglMap
        :mapStyle="style"
        :attributionControl="false"
        :center="center"
        :zoom="zoom"
        @map:load="onMapLoad"
        <!-- @map:click="handleMapClick" -->
      >
        <MglNavigationControl position="top-right" />
        <MglMarker
          ref="markerRef"
          :coordinates="markerPosition"
          color="red"
          draggable
          @dragend="handleDragEnd"
          @dragstart="dragging=true"
          @update:coordinates="handleMarkerCoordinatesChange"
        >
          <MglPopup v-if="props.modelValue?.formatted_address" :offset="[0, 25]">
            <div class="p-2 bg-background/80 backdrop-blur-sm">
              {{ props.modelValue.formatted_address }}
            </div>
          </MglPopup>
        </MglMarker>
      </MglMap>
    </ClientOnly>
  </div>
</template>

<style>
@import "maplibre-gl/dist/maplibre-gl.css";
</style> 