<template>
    <!-- https://github.com/radix-ui/primitives/issues/1272 -->
    <DropdownMenu v-if="user" v-model:open="isOpen">
        <div v-if="plan?.product?.name" :class="`hidden sm:block whitespace-nowrap -mr-2 -mt-0.5 rounded-full`">
            <small
                :class="`pr-1 px-1.5 py-1 bg-primary text-primary-foreground rounded-full text-xs font-semibold text-right select-none`">
                {{ plan.product.name }}
                <Icon v-if="plan?.product?.name === 'Teams'" name="lucide:users" class="mx-1" />
                <Icon v-else name="lucide:user" class="mx-1" />
            </small>
        </div>
        <!-- <small>{{ selectedPlan }}</small> -->
        <DropdownMenuTrigger class="cursor-pointer z-10">
            <Avatar v-if="user" class="hidden border-[1.5px] border-primary">
                <!-- <AvatarImage :src="user?.picture" :alt="user?.name" /> -->
                <AvatarFallback>
                    {{ user?.email?.charAt(0).toUpperCase()}}
                </AvatarFallback>
            </Avatar>
            <div v-if="user"
                :class="['w-10 h-10 flex flex-cols justify-center items-center masked', `bg-[url('https://avatar.vercel.sh/${user.email}.png')]`]">
                <clientOnly>
                <div class="top-0 left-0 w-full h-full" v-html="avatar(user.email, 48)"/>
                </clientOnly>
                <p class="absolute text-xl text-white ">
                    {{ user?.email?.charAt(0).toUpperCase()}}
                </p>
            </div>
            <!-- <Icon v-else name="lucide:menu" class="mx-3"/> -->
        </DropdownMenuTrigger>
        <!-- m-3 -mt-1  -->
        <DropdownMenuContent class="w-64 mr-[2vw] h-fit  flex flex-col justify-between">
            <!-- <DropdownMenuItem v-if="user">
                <DropdownMenuLabel class="font-normal flex">
                    <div class="flex flex-col space-y-1">
                        <p class="text-sm font-medium leading-none">
                            {{ user?.name }}
                        </p>
                        <div class="w-44">

                            <p class="text-xs truncate w-auto text-muted-foreground">
                                {{ user.display_name || user?.email }}
                            </p>
                        </div>
                    </div> 
                </DropdownMenuLabel>
            </DropdownMenuItem> -->


            <AccountSelectTeam @close-menu="isOpen = false" />



            <DropdownMenuLabel v-for="(item, index) in menuItem.filter((item) => !item.role || role === item.role)"
                :key="index" class="font-normal flex">
                <div v-if="item.label !== 'divider'" class="flex flex-col space-y-1 w-full">
                    <DropdownMenuItem class="cursor-pointer text-foreground" @click="handleDropdownSelect(item.label.toLowerCase())">
                        <Icon :name="item.icon" class="mr-2 h-4 w-4" />
                        {{ item.label }}
                    </DropdownMenuItem>
                </div>
                <DropdownMenuSeparator v-else />
            </DropdownMenuLabel>


        </DropdownMenuContent>
    </DropdownMenu>
    <Button v-else variant="ghost" class="select-none">
        <NuxtLink to="/login">

        </NuxtLink>
    </Button>

</template>

<script setup lang="ts">
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import avatar from 'gradient-avatar'

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const UserStore = useUserStore()
const { role } = UserStore
const { plan } = storeToRefs(UserStore)
const uiStore = useUiStore()
const { selectedPlan } = storeToRefs(uiStore)



const user = useSupabaseUser()
// add role to user object


const client = useSupabaseClient()

const logout = async () => {
  const { error } = await client.auth.signOut()
    if (error) {
    console.error(error)
    return alert('Something went wrong !')
    }
  
}



const menuItem = computed(() => {
    if (user.value) {
        return [
            { label: 'Account', icon: 'lucide:user-2', role:'admin' },
            { label: 'Admin', icon: 'lucide:layout-dashboard', role: 'admin' },
            { label: 'divider' },
            {
                label: 'Sign out',
                icon: 'lucide:log-out'
            }
        ]
    } else {
        return [
            { 
                label: user?.value ? 'Sign out' : 'Sign in',
                icon: `lucide:log-${user?.value ? 'out' : 'in'}`
            }
        ]
    }
})




async function handleDropdownSelect(key: string) {
    if (key === 'sign out') {
        const { error } = await client.auth.signOut()
        // navigate to /login
        await navigateTo('/login')
        if (error) console.log(error)
        return
    } 
    
    let item = menuItem.value.find((item) => item.label.toLowerCase() === key)
    if (!item) return
    await navigateTo(item.label.toLowerCase())
}

const isOpen = ref(false)

</script>

<style scoped>
.masked {
    mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
    mask-repeat: no-repeat;
    mask-size: 100%;
}
</style>
