import { squircleObserver, type SquircleOptions } from '~/utils/squircle'

export function useSquircleObserver(dependencies: any[] = []) {
  const observers = ref<Array<{ disconnect: () => void; reapply: () => void }>>([])

  const reapplySquircles = () => {
    observers.value.forEach(observer => observer.reapply())
  }

  onMounted(() => {
    const elements = Array.from(
      document.querySelectorAll("[class*='squircle-']")
    ) as HTMLElement[]

    observers.value = elements.map(el => {
      const className = el.className
      const match = className.match(/squircle-(\d+)/)
      const cornerRadius = match ? parseInt(match[1], 10) : 15

      const options: SquircleOptions = {
        cornerRadius,
        cornerSmoothing: 1,
        preserveSmoothing: true,
        borderWidth: 0
      }

      return squircleObserver(el, options)
    })
  })

  onUnmounted(() => {
    observers.value.forEach(observer => observer.disconnect())
  })

  if (dependencies.length) {
    watch(dependencies, () => {
      nextTick(reapplySquircles)
    })
  }

  return {
    reapplySquircles
  }
}