<script lang="ts" setup>
import { Loader2 } from 'lucide-vue-next';

interface InviteLink {
  link: string;
  code: string;
}

const { invites } = defineProps<{
  invites: InviteLink[] | null
}>()

const copied = ref<boolean[]>([])

watch(() => invites, (newInvites) => {
  if (newInvites) {
    copied.value = new Array(newInvites.length).fill(false)
    // refresh the 
  } else {
    copied.value = []
  }
}, { immediate: true })

function copyInviteLink(link: string, index: number) {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      copied.value[index] = true
    })
    .catch((err) => {
      console.error("Failed to copy invite link to clipboard", err);
    });
}
</script>

<template>
  <div class="w-full" :key="invites?.length">
    <Skeleton v-if="!invites" class="h-10 rounded-md contrast-150 brightness-[0.8]" />
    <div v-else v-for="(invite, index) in invites" :key="index"
      class="flex items-center space-x-2 pl-0.5 truncate">
      <span :id="`link-${index}`"
        class="flex h-10 max-w-[300px] overflow-x-hidden rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
        {{ invite.link }}
      </span>

      <Button variant="outline" @click="copyInviteLink(invite.link, index)" class="my-1">
        <Icon v-if="!copied[index]" name="lucide:copy" />
        <Icon v-else name="lucide:check" class="text-emerald-600" />
      </Button>
    </div>
  </div>
</template>

<style scoped></style>
