import { getSvgPath } from "figma-squircle"

export interface SquircleOptions {
  cornerRadius: number
  topLeftCornerRadius?: number
  topRightCornerRadius?: number
  bottomRightCornerRadius?: number
  bottomLeftCornerRadius?: number
  cornerSmoothing?: number
  preserveSmoothing?: boolean
  borderWidth?: number
}

const createClassName = (length: number = 8): string => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  return `squircle-${Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('')}`
}

const setCss = (css: string, styleId: string): void => {
  let styleEl = document.getElementById(styleId)
  if (!styleEl) {
    styleEl = document.createElement("style")
    styleEl.id = styleId
    document.head.appendChild(styleEl)
  }
  styleEl.textContent = css
}

const renderSquircle = (el: HTMLElement, opts: SquircleOptions, className: string): void => {
  const width = el.clientWidth
  const height = el.clientHeight

  const augmentedOpts = {
    preserveSmoothing: true,
    cornerSmoothing: 1,
    width,
    height,
    ...opts,
  }

  if (!opts.borderWidth) {
    el.style.clipPath = `path('${getSvgPath(augmentedOpts)}')`
  } else {
    el.classList.add(className)

    setCss(
      `
        .${className} {
          position: relative;
          clip-path: path('${getSvgPath(augmentedOpts)}');
        }

        .${className}::before {
          content: '';
          display: block;
          position: absolute;
          inset: ${opts.borderWidth}px;  
          z-index: -1;
          clip-path: path('${getSvgPath({
            ...augmentedOpts,
            width: width - opts.borderWidth * 2,
            height: height - opts.borderWidth * 2,
            cornerRadius: opts.cornerRadius - opts.borderWidth,
          })}');
        }
      `,
      `style-${className}`
    )
  }
}

export const squircleObserver = (el: HTMLElement, opts: SquircleOptions) => {
  const className = createClassName()
  let dimensions: [number, number] | undefined = undefined

  const func = (newOpts?: SquircleOptions) => {
    if (newOpts !== undefined) {
      opts = newOpts
    }
    return renderSquircle(el, opts, className)
  }

  const resizeObserver = new ResizeObserver(() => {
    const prevDimensions = dimensions
    dimensions = [el.clientWidth, el.clientHeight]

    if (
      prevDimensions?.[0] !== dimensions[0] ||
      prevDimensions?.[1] !== dimensions[1]
    ) {
      func()
    }
  })

  resizeObserver.observe(el)

  func.disconnect = () => {
    el.classList.remove(className)
    document.querySelector(`#style-${className}`)?.remove()
    resizeObserver.disconnect()
  }

  func.reapply = () => {
    func()
  }

  return func
}