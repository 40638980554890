export function useScrollbarWidth() {
    const width = ref(0)
  
    const calculate = () => {
      // Create outer container
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.overflow = 'scroll'
      outer.style.msOverflowStyle = 'scrollbar'
      document.body.appendChild(outer)
  
      // Create inner element
      const inner = document.createElement('div')
      outer.appendChild(inner)
  
      // Calculate width difference
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
  
      // Cleanup
      outer.parentNode?.removeChild(outer)
  
      width.value = scrollbarWidth
    }
  
    // Calculate on mount
    onMounted(() => {
      calculate()
    })
  
    return {
      width,
      calculate
    }
  }