<script setup lang="ts">
import { useAttrs } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  defaultValue?: string | number
  modelValue?: string | number
  type?: string
  leading?: boolean
  trailing?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const { class: className, ...rest } = useAttrs()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})
</script>

<template>
  <div class="relative w-full">
    <div 
      v-if="props.leading"
      class="absolute inset-y-0 left-0 pl-3 flex items-center"
    >
      <slot name="leading">
        <span class="text-muted-foreground">
          <slot name="leading-content" />
        </span>
      </slot>
    </div>

    <input 
      v-model="modelValue" 
      :type="props.type" 
      :class="cn(
        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background',
        'file:border-0 file:bg-transparent file:text-sm file:font-medium',
        'placeholder:text-muted-foreground/70',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        props.leading && 'pl-10',
        props.trailing && 'pr-10',
        className ?? ''
      )" 
      v-bind="rest"
    >

    <div 
      v-if="props.trailing"
      class="absolute inset-y-0 right-0 pr-3 flex items-center"
    >
      <slot name="trailing">
        <span class="text-muted-foreground">
          <slot name="trailing-content" />
        </span>
      </slot>
    </div>
  </div>
</template>