<template>
  <div class="relative w-full" ref="containerRef" @click.stop>
    <Input
      ref="inputRef"
      class="w-full"
      v-model="inputValue"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInputChange"
      @keydown.down.prevent="highlightNext"
      @keydown.up.prevent="highlightPrev"
      @keydown.enter.prevent="selectHighlighted"
      @keydown.esc="openDropDown = false"
    />
    <div
      v-if="(openDropDown && filteredItems.length > 0) || (showUpdatePrompt && suggestedAddress)"
      class="absolute z-50 w-full bg-white border border-gray-200 shadow-lg mt-1 rounded-md max-h-60 overflow-auto dark:bg-gray-800 dark:border-gray-700"
    >
      <div
        v-if="showUpdatePrompt && suggestedAddress"
        @mousedown.prevent="handleDropdownSelect({ description: suggestedAddress, place_id: 'suggested' })"
        class="px-4 py-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2"
      >
        <Icon name="heroicons:map-pin" class="w-5 h-5 text-primary" />
        <div>
          <div class="text-sm text-muted-foreground">Update address to:</div>
          <div class="font-medium">{{ suggestedAddress }}</div>
        </div>
      </div>
      
      <template v-else>
        <div
          v-for="(item, index) in filteredItems"
          :key="item.place_id"
          @mousedown.prevent="handleDropdownSelect(item)"
          :class="[
            'px-4 py-2 cursor-pointer',
            index === highlightedIndex ? 'bg-primary text-primary-foreground' : 'hover:bg-muted',
          ]"
        >
          <span v-html="item.highlightedDescription"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useFetch } from '#imports'
import { Input } from '@/components/ui/input'

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    default: ''
  },
  initialValue: {
    type: String,
    default: ''
  },
  showUpdatePrompt: {
    type: Boolean,
    default: false
  },
  suggestedAddress: {
    type: String,
    default: ''
  }
})

const emit = defineEmits<{
  select: [{ description: string, place_id: string }]
  focus: []
  blur: []
  'update:modelValue': [string]
  'update:showUpdatePrompt': [boolean]
}>()

const inputValue = ref(props.modelValue || props.initialValue || '')

watch([() => props.modelValue, () => props.initialValue], ([newModelValue, newInitialValue]) => {
  if (newModelValue && newModelValue !== inputValue.value) {
    inputValue.value = newModelValue
  } else if (newInitialValue && newInitialValue !== inputValue.value) {
    inputValue.value = newInitialValue
  }
})

watch(() => inputValue.value, (newValue) => {
  if (newValue) {
    emit('update:modelValue', newValue)
  }
})

const openDropDown = ref(false)
const containerRef = ref<HTMLElement | null>(null)
const inputRef = ref<{ $el: HTMLElement } | null>(null)
const highlightedIndex = ref(-1)
const filteredItems = ref<any[]>([])

const headers = useRequestHeaders(['cookie'])

// Fetch predictions when input changes
const fetchPredictions = async () => {
  if (inputValue.value.trim() === '') {
    filteredItems.value = []
    return
  }

  try {
    const { data } = await useFetch('/api/geocode/place', {
      method: 'POST',
      headers,
      body: { address: inputValue.value, query: inputValue.value },
    })
    if (data.value?.predictions) {
      filteredItems.value = data.value.predictions.map((item: any) => {
        const highlightedDescription = highlightMatches(item.description, item.matched_substrings)
        return { description: item.description, place_id: item.place_id, highlightedDescription }
      })
    } else {
      filteredItems.value = []
    }
  } catch (error) {
    console.error('Error fetching predictions:', error)
    filteredItems.value = []
  }
}

// Highlight matches in the suggestions
const highlightMatches = (description: string, matchedSubstrings: any[]) => {
  let result = ''
  let lastIndex = 0

  matchedSubstrings.forEach((substring: any) => {
    const start = substring.offset
    const end = start + substring.length
    result += description.slice(lastIndex, start) + `<span class="font-bold">${description.slice(start, end)}</span>`
    lastIndex = end
  })

  result += description.slice(lastIndex)
  return result
}

// Handle input change
const onInputChange = () => {
  if (props.showUpdatePrompt) {
    emit('update:showUpdatePrompt', false)
  }
  openDropDown.value = true
  fetchPredictions()
}

// Handle selection
const handleDropdownSelect = (item: { description: string, place_id: string }) => {
  // Update input value
  inputValue.value = item.description
  
  // Emit selection
  emit('select', { description: item.description, place_id: item.place_id })
  
  // Update model value
  emit('update:modelValue', item.description)
  
  // Close dropdown and reset update prompt
  openDropDown.value = false
  if (item.place_id === 'suggested') {
    emit('update:showUpdatePrompt', false)
  }
}

// Keyboard navigation
const highlightNext = () => {
  if (highlightedIndex.value < filteredItems.value.length - 1) {
    highlightedIndex.value++
  }
}

const highlightPrev = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--
  }
}

const selectHighlighted = () => {
  if (highlightedIndex.value >= 0 && highlightedIndex.value < filteredItems.value.length) {
    handleDropdownSelect(filteredItems.value[highlightedIndex.value])
  }
}

onMounted(() => {
  document.addEventListener('click', onClickOutside)
})

const onClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (!containerRef.value?.contains(target) && openDropDown.value) {
    openDropDown.value = false
  }
}

onUnmounted(() => {
  document.removeEventListener('click', onClickOutside)
})

// Add focus/blur handlers
const onFocus = () => {
  openDropDown.value = true
  emit('focus')
}

const onBlur = () => {
  if (!props.showUpdatePrompt) {
    setTimeout(() => {
      openDropDown.value = false
      emit('blur')
    }, 200)
  } else {
    openDropDown.value = true
  }
}

watch(() => props.showUpdatePrompt, (newValue) => {
  if (newValue && props.suggestedAddress) {
    openDropDown.value = true
    filteredItems.value = []
    inputRef.value?.$el?.focus()
  }
}, { immediate: true })

watch([() => props.suggestedAddress, () => props.showUpdatePrompt], ([newAddress, showPrompt]) => {
  if (newAddress && showPrompt) {
    openDropDown.value = true
    filteredItems.value = []
    setTimeout(() => {
      openDropDown.value = true
    }, 0)
  }
})
</script>

<style scoped>
/* Customize the dropdown styles as needed */
</style>
