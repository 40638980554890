<script setup lang="ts">
import { useParallax } from '@vueuse/core'
import { useBreakpoints } from '@vueuse/core'

const { src, title, tilt, roll, width, height, blur = true, parallax = true } = defineProps({
  src: {
    type: [String, Object],
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  tilt: {
    type: Number,
    required: true,
  },
  roll: {
    type: Number,
    required: true,
  },
  width: {
    type: [String, Number],
    default: '500px'
  },
  height: {
    type: [String, Number],
    default: '500px'
  },
  blur: {
    type: Boolean,
    default: true
  },
  parallax: {
    type: Boolean,
    default: true
  }
})

const base_url = useRuntimeConfig().public.imagekit_base_url

const target = ref(null)
const { roll: pRoll, tilt: pTilt } = useParallax(target)

const rotateCoeff = ref(30)
const translateCoeff = ref(15)

const breakpoints = useBreakpoints({
  md: 768 // matches Tailwind's default md breakpoint
})
const isMobile = computed(() => !breakpoints.greater('md').value)

const imageSrc = computed(() => {
  if (typeof src === 'string') {
    return `${base_url}${src}`
  }
  return `${base_url}${src.path || src.url}`
})

const cardStyle = computed(() => ({
  transform: parallax 
    ? `rotateY(${pTilt.value * rotateCoeff.value}deg)
       translateX(${pTilt.value * translateCoeff.value}px)
       ${isMobile.value ? `rotateX(${pRoll.value * rotateCoeff.value}deg)
       translateY(${pRoll.value * translateCoeff.value}px)` : ''}
       scale(1.33)`
    : 'scale(1.33)',
}))

</script>

<template>
  <div ref="target" class="masked transition-all duration-150 ease-in-out">
    <div :style="{ perspective: '100px', ...cardStyle }">
      <div id="card">
        <div id="layer0" class="hover:scale-[0.95] transition-all duration-100 ease-out">
          <img 
            :src="imageSrc"
            class="select-none transition-all duration-150 ease-out aspect-square"
            :class="{
              'sm:blur-[3px] hover:blur-none sm:saturate-[0.5] hover:saturate-150': blur
            }"
            :width="width"
            :height="height"
            :alt="title" 
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="width === '500px'"
    class="pointer-events-none select-none absolute top-0 flex w-full justify-center items-center align-center aspect-square scale-[0.9] sm:scale-[0.8] md:scale-[0.9] lg:scale-[0.8] lg:-mt-0 lg:scale-[0.9]">
    <CircleTicker :title="title" :text="title" />
  </div>
</template>

<style>
.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: contain;
}

</style>
