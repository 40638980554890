<script setup lang="ts">
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { Separator } from '@/components/ui/separator'

interface TeamMember {
  id: string
  user_id: string
  email: string
  full_name: string
  role?: string
  avatar_url?: string
}

const props = defineProps<{
  members: TeamMember[]
  onClose?: () => void
  groupId: string
}>()

const emit = defineEmits<{
  'update:loading': [loading: boolean]
}>()

const roles = ['owner', 'admin', 'member', 'viewer']
const updating = ref(false)
const memberRoles = ref<Record<string, string>>({})
const fetchingRoles = ref(false)

const updateRole = async (member: TeamMember, newRole: string) => {
  if (!member?.id || !props.groupId) {
    console.error('Missing required IDs', { 
      member,
      userId: member?.id,
      groupId: props.groupId
    })
    return
  }

  updating.value = true
  emit('update:loading', true)
  
  try {
    const client = useSupabaseClient()
    
    const { error, data } = await client
      .from('group_users')
      .upsert({
        user_id: member.id,
        group_id: props.groupId,
        role: newRole,
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'group_id,user_id,role',  // Match the exact unique index
        ignoreDuplicates: false  // We want to update if there's a conflict
      }).select()

    if (error) throw error
    
    member.role = newRole

  } catch (error) {
    console.error('Failed to update role:', error)
  } finally {
    updating.value = false
    emit('update:loading', false)
  }
}

const getInitials = (member: TeamMember) => {
  if (member.full_name) {
    return member.full_name.split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
  }
  return member.email[0].toUpperCase()
}

// Add a helper to capitalize role names
const formatRole = (role: string) => {
  return role.charAt(0).toUpperCase() + role.slice(1)
}

const fetchMemberRoles = async () => {
  if (!props.groupId) return
  
  fetchingRoles.value = true
  emit('update:loading', true)
  
  try {
    const client = useSupabaseClient()
    const { data, error } = await client
      .from('group_users')
      .select('user_id, role')
      .eq('group_id', props.groupId)
    
    if (error) throw error
    
    // Create a map of user_id to role
    memberRoles.value = (data || []).reduce((acc, { user_id, role }) => ({
      ...acc,
      [user_id]: role
    }), {})
    
    // Update the members with their roles
    props.members.forEach(member => {
      member.role = memberRoles.value[member.user_id] || 'member' // Default to member
    })
  } catch (error) {
    console.error('Failed to fetch member roles:', error)
  } finally {
    fetchingRoles.value = false
    emit('update:loading', false)
  }
}

// Add onMounted hook
onMounted(() => {
  fetchMemberRoles()
})
</script>

<template>
  <div class="space-y-6">
    <div class="space-y-1.5">
      <h2 class="text-lg font-semibold">Set roles and permissions</h2>
    </div>

    <div class="space-y-4">
      <div v-for="member in members" :key="member.user_id" 
        class="flex items-center justify-between p-3 rounded-lg">
        <div class="flex items-center gap-4">
          
          <Avatar class="h-9 w-9">
            <AvatarImage 
              v-if="member.avatar_url"
              :src="member.avatar_url" 
              :alt="member.full_name || member.email" 
            />
            <AvatarFallback class="bg-primary/10">
              {{ getInitials(member) }}
            </AvatarFallback>
          </Avatar>
          
          <div class="flex flex-col items-start">
            <span class="text-sm font-medium">
              {{ member.full_name || 'Unnamed Member' }}
            </span>
            <span class="text-sm text-muted-foreground">
              {{ member.email }}
            </span>
          </div>
        </div>
        
        <Select 
          :model-value="member.role" 
          :disabled="updating"
          @update:model-value="(role) => updateRole(member, role)"
        >
          <SelectTrigger class="w-[110px] border-1 bg-muted hover:bg-neutral-100 dark:hover:bg-neutral-800">
            
              <SelectValue class="whitespace-nowrap">
                {{ member.role ? formatRole(member.role) : 'Select role' }}
              </SelectValue>
            
          </SelectTrigger>
          <SelectContent>
            <SelectItem v-for="role in roles" :key="role" :value="role">
              <span class="capitalize whitespace-nowrap">{{ role }}</span>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.select-trigger) {
  @apply border-0 shadow-none rounded-md transition-colors duration-200;
}

:deep(.select-value) {
  @apply truncate;
}

:deep(.select-content) {
  @apply min-w-[110px];
}
</style> 