<template>
  <ClientOnly>
    <div class="pt-0 sm:pt-2 pl-3 sm:pl-20 md:pl-14 relative">
      <Breadcrumb v-if="crumbs.length">
        <BreadcrumbList class="flex items-center whitespace-nowrap flex-nowrap gap-2 scale-75 pt-4 sm:pt-0 origin-top-left sm:scale-100">
          <BreadcrumbItem 
            v-for="crumb in crumbs" 
            :key="crumb.path" 
            class="flex-shrink-0"
            :class="{
              'relative z-50': crumb.route === 'type-team-project' || crumb.route === 'type-team'
            }"
          >
            <!-- Project crumb -->
            <template v-if="crumb.route === 'type-team-project' && selectedProject">
              <ProjectMenu />
            </template>
            
            <!-- Team crumb -->
            <template v-else-if="crumb.route === 'type-team' && selectedTeam">
              <TeamMenu />
            </template>
            
            <!-- Teams root crumb -->
            <template v-else-if="crumb.route === 'team'">
              <NuxtLink :to="crumb.path" :prefetch="false" class="block">
                <BreadcrumbLink class="capitalize">
                  <span class="truncate max-w-[100px] sm:max-w-[200px] py-0 my-0">Teams</span>
                </BreadcrumbLink>
              </NuxtLink>
            </template>
            
            <!-- Default crumb -->
            <template v-else>
              <NuxtLink :to="crumb.path" :prefetch="false" class="block">
                <BreadcrumbLink class="capitalize">
                  <span class="truncate max-w-[100px] sm:max-w-[200px] py-0 my-0">{{ getBreadcrumbTitle(crumb) }}</span>
                </BreadcrumbLink>
              </NuxtLink>
            </template>

            <BreadcrumbSeparator v-if="crumb.path !== crumbs[crumbs.length - 1].path">
              <Icon name="material-symbols:circle" class="scale-50 text-muted-foreground/30" />
            </BreadcrumbSeparator>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  </ClientOnly>
</template>

<script setup>
import { apStyleTitleCase } from 'ap-style-title-case'
import { useProjectData } from '@/composables/projectData'
import ProjectMenu from './ProjectMenu.vue'
import TeamMenu from './TeamMenu.vue'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const {selectedTeam, user} = storeToRefs(userStore)

const projectStore = useProjectStore()
const {selectedProject, projects} = storeToRefs(projectStore)

const crumbs = computed(() => {
  const fullPath = route.fullPath
  const params = fullPath.startsWith('/')
    ? fullPath.substring(1).split('/')
    : fullPath.split('/')
  const trail = []
  let path = ''
  params.forEach((param, index) => {
    if (param === '') return // Skip the root path
    path = `${path}/${param}`
    const match = router.resolve(path)
    if (match.name !== null) {
      // Skip if this is the root 'team' route
      if (match.name === 'team') return

      let title = param.replace(/-/g, ' ')
      // Check if the current param matches the team param
      if (param === route.params?.team && selectedTeam?.value?.metadata) {
        title = selectedTeam.value.metadata.name
      }
      // Check if the current param matches any project ID
      if (projects.value?.length) {
        const project = projects.value.find(proj => proj.id == param)
        if (project) {
          title = project.title
        }
      } 
      // Check if the current param matches the user ID
      if (param === user.value?.id) {
        title = user.value.user_metadata.name
      }
      
      trail.push({
        title,
        path: match.path,
        route: match.name
      })
    }
  })
  return trail
})

const getBreadcrumbTitle = (crumb) => {
  if (crumb.route === 'type-team-project' && projects?.length && selectedProject) {
    return crumb.title;
  } else if (crumb.route === 'team') {
    return 'Teams';
  } else if (crumb.route === 'user') {
    return 'Personal';
  } else {
    return crumb.title;
  }
};
</script>

<style scoped>
.breadcrumb-container {
  position: relative;
  overflow: visible;
}

.breadcrumb-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.breadcrumb-list::-webkit-scrollbar {
  display: none;
}
</style>

