import { default as _91project_93LbCjqER0x3Meta } from "/vercel/path0/pages/[type]/[team]/[project].vue?macro=true";
import { default as indexkzBk34yvEZMeta } from "/vercel/path0/pages/[type]/[team]/index.vue?macro=true";
import { default as AboutJ8r2jRfvwUMeta } from "/vercel/path0/pages/About.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as admin4FCB2UHG0ZMeta } from "/vercel/path0/pages/admin.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as _91_46_46_46slug_93FZaI6W8xt6Meta } from "/vercel/path0/pages/dashboard/[...slug].vue?macro=true";
import { default as settingsiT0n0ZleJjMeta } from "/vercel/path0/pages/dashboard/settings.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invite_45onlyOpzxDHqaBpMeta } from "/vercel/path0/pages/invite-only.vue?macro=true";
import { default as _91_46_46_46code_93zwHawFg40cMeta } from "/vercel/path0/pages/invite/[...code].vue?macro=true";
import { default as privacyEXft7RtKWLMeta } from "/vercel/path0/pages/legal/privacy.vue?macro=true";
import { default as tosiHUJIKyjjgMeta } from "/vercel/path0/pages/legal/tos.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as cancelxsDtSJrRzvMeta } from "/vercel/path0/pages/subscribe/cancel.vue?macro=true";
import { default as success98deXjElj0Meta } from "/vercel/path0/pages/subscribe/success.vue?macro=true";
import { default as teamThGqkFwfKIMeta } from "/vercel/path0/pages/team.vue?macro=true";
import { default as telescopicBMwfY9liuaMeta } from "/vercel/path0/pages/telescopic.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as testingSuzh51DFIKMeta } from "/vercel/path0/pages/testing.vue?macro=true";
import { default as userNoaTvvG4iQMeta } from "/vercel/path0/pages/user.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
import { default as _91_46_46_46stripe_connect_result_93y94MDdar9BMeta } from "/vercel/path0/pages/wellspring/[...stripe_connect_result].vue?macro=true";
export default [
  {
    name: "type-team-project",
    path: "/:type()/:team()/:project()",
    component: () => import("/vercel/path0/pages/[type]/[team]/[project].vue").then(m => m.default || m)
  },
  {
    name: "type-team",
    path: "/:type()/:team()",
    meta: indexkzBk34yvEZMeta || {},
    component: () => import("/vercel/path0/pages/[type]/[team]/index.vue").then(m => m.default || m)
  },
  {
    name: "About",
    path: "/About",
    component: () => import("/vercel/path0/pages/About.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-slug",
    path: "/dashboard/:slug(.*)*",
    meta: _91_46_46_46slug_93FZaI6W8xt6Meta || {},
    component: () => import("/vercel/path0/pages/dashboard/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    component: () => import("/vercel/path0/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-only",
    path: "/invite-only",
    component: () => import("/vercel/path0/pages/invite-only.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code(.*)*",
    meta: _91_46_46_46code_93zwHawFg40cMeta || {},
    component: () => import("/vercel/path0/pages/invite/[...code].vue").then(m => m.default || m)
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    meta: privacyEXft7RtKWLMeta || {},
    component: () => import("/vercel/path0/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: "legal-tos",
    path: "/legal/tos",
    meta: tosiHUJIKyjjgMeta || {},
    component: () => import("/vercel/path0/pages/legal/tos.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "subscribe-cancel",
    path: "/subscribe/cancel",
    component: () => import("/vercel/path0/pages/subscribe/cancel.vue").then(m => m.default || m)
  },
  {
    name: "subscribe-success",
    path: "/subscribe/success",
    component: () => import("/vercel/path0/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/vercel/path0/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "telescopic",
    path: "/telescopic",
    component: () => import("/vercel/path0/pages/telescopic.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "testing",
    path: "/testing",
    meta: testingSuzh51DFIKMeta || {},
    component: () => import("/vercel/path0/pages/testing.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/vercel/path0/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/vercel/path0/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: "wellspring-stripe_connect_result",
    path: "/wellspring/:stripe_connect_result(.*)*",
    meta: _91_46_46_46stripe_connect_result_93y94MDdar9BMeta || {},
    component: () => import("/vercel/path0/pages/wellspring/[...stripe_connect_result].vue").then(m => m.default || m)
  }
]